import React from "react"
import styled from "styled-components"

import { midGray, inputTextColor } from "mill/utils/colors"
import { bodyFont, inputFontSize } from "mill/utils/settings"

export const InputContainer = styled.div`
  position: relative;
`

export const Icon = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  background: #f1f1f1;
  border-right: 1px solid ${midGray};
  border-radius: 3px 0 0 3px;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextInput = styled.input`
  min-width: 26rem;
  border-radius: 3px;
  border-color: ${midGray};
  border-width: 1px;
  border-style: solid;
  padding: 0.6rem 1.3rem;
  font-family: ${bodyFont};
  font-size: ${inputFontSize};
  line-height: 2.2rem;
  transition: all 0.2s ease-out;
  color: ${inputTextColor};

  &:disabled {
    background-color: #efefef;
  }

  ${props =>
    props.hasIcon &&
    `
 padding-left: 5.4rem;
 `}

  ${props =>
    props.fullWidth &&
    `
    width: 100%;
  `} &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    transition: all 0.2s ease-in;
    ${props =>
      props.hasError &&
      `
      border-color: #D32F2F;
    `};
  }

  ${props =>
    props.hasError &&
    `
    border-color: #D32F2F;
  `};
`
